:focus {
  outline: none;
}
.borderloader {
  background-color: #fff;
  border-radius: 2rem;
  padding: 20px;
}
.borderloader p {
  margin-bottom: 0;
}
body {
  margin: 0px;
  overflow-x: hidden;
}
.btn-blank {
  border: 2px solid #fff!important;
  padding: 10px 20px!important;
  background: none!important;
  color: #fff!important;
  font-weight: bold!important;
  text-transform: capitalize!important;
}
.btn-orange {
  border: 2px solid #f68e58!important;
  padding: 10px 20px!important;
  background: #f68e58!important;
  color: #fff!important;
  font-weight: bold!important;
  text-transform: capitalize!important;
}
.partnerships {
  padding: 60px 100px;
}
.partner {
  padding: 60px 10%;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.textpurple { 
  color: #40247e;
}
.textorange {
  color: #FF6F00;
}
.text-bold {
  font-weight: bold!important;
}
.text-large {
  font-size: large;
}
.text-xlarge {
  font-size: x-large;
}
.text-center {
  text-align: center!important;
  display: block!important;
}
.bg-abu {
  background: #f5f5f5;
  padding: 50px!important;
}
.bgabu {
  background: #f5f5f5;
}
.banner-text {
  padding: 20px 20px 10px!important;
  font-weight: bold!important;
  color: #fff;
  line-height: 1.15!important;
  text-shadow: 3px 3px 4px rgba(0,0,0,.3);
}
.banner-text2 {
  margin-bottom: 30px!important;
  font-weight: bold!important;
  color: #fff;
  line-height: 1.15!important;
  text-shadow: 3px 3px 4px rgba(0,0,0,.3);
}
.img-socmed {
  margin-top: -15px;
}
.img-socmed ul {
  list-style: none;
  display: inline-block;
  padding-left: 0;
}
.img-socmed ul li {
  float: left;
  margin-right: 20px;
}
.img-socmed ul li:last-child {
  margin-right: 0;
}
.img-socmed ul li img {
  width: 40px;
  border: 0px;
}
.thankyou {
  font-family: Roboto;
}
.thankyou h1 {
  margin-bottom: 0;
}
.thankyou h3 {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 400;
}
.thankyou p {
  font-size: 14px;
}
.thankyou img {
  width: 80px;
  border: 3px solid #707070;
  margin-right: 15px;
}
.box-border{
  -webkit-box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.06)!important;
  -moz-box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.06)!important;
  box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.06)!important;
  border-radius: 14px;
  border: 1px solid rgba(0, 0, 0, 0.06)!important;
  padding: 30px;
  margin: 15px 0 30px;
  min-height: 200px;
}
.first .box-border {
  margin-left: 20%!important;
  margin-right: 1%;
}
.last .box-border {
  margin-right: 20%!important;
  margin-left: 1%;
}
.vertical-align {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.padding20 {
  padding: 24px 22px 20px;
}
.imgrounded {
  border-radius: 50%;
}
@media (max-width: 959px) {
  .first .box-border {
    margin-left: 10%!important;
    margin-right: 10%;
  }
  .last .box-border {
    margin-right: 10%!important;
    margin-left: 10%;
  }
}
@media (max-width: 767px) {
  .partnerships, .partner {
    padding: 60px 30px;
    text-align: center;
  }
  .partner-right {
    padding: 10px 30px!important;
  }
  .partner-right h5 {
    margin-bottom: 15px;
  }
  .partner-right .MuiGrid-item {
    margin: auto;
  }
  .banner-text {
    font-size: xx-large!important;
  }
  .banner-text2 {
    font-size: large!important;
  }
  .container-abu {
    display: block!important;
  }
}
@media (max-width: 480px) {
  .first .box-border {
    margin-left: 30px!important;
    margin-right: 15px;
  }
  .last .box-border {
    margin-right: 30px!important;
    margin-left: 15px;
  }
  .thankyou {
    padding: 0 30px;
  }
  .thankyou img {
    margin-right: 8px;
  }
  .img-socmed ul li img {
    width: 35px;
  }
}