@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  src: local("Montserrat"), url("./Montserrat-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  src: local("Montserrat"), url("./Montserrat-Bold.otf") format("opentype");
}
